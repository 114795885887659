<template>
  <st-box>
    <div class="mb-32">
      <st-form-title
        :formTitle="$t('studio.transfer.mgmt_history.detail_pg_info1_grp_name')"
        required
      />

      <input-text
        name="groupTransfer.groupName"
        :placeholder="$t('studio.transfer.mgmt_history.detail_pg_info1_grp_name_place_holder')"
        :rules="{
          required: {
            value: true,
            message: $t('studio.transfer.mgmt_history.detail_pg_info1_grp_name_req_msg')
          },
          max_length: {
            length: 60,
            message: $t('studio.common.def_key.exceed', { length: 60 })
          }
        }"
        :countable="true"
        maxLength="60"
        :disabled="modeDisabled"
      />
    </div>
    <license-check :disabled="disableKrField" />
    <!-- 3.1-3-1 (9) -->
    <st-form-title
      :formTitle="$t('studio.business_bank_setting.individual.info_view_o_adress')"
      required
      class="mt-32"
    />
    <div class="relative">
      <dropdown
        rules="required"
        name="individual.businessInfo.nation"
        :options="countryOptions"
        :disabled="disableKrField || disableOtherNationField"
        :dropdownProps="{
          size: 'lg',
          variant: 'line',
          distance: 0,
          offset: [0, 0],
          placement: 'bottom-start',
          class: 'w-full'
        }"
        :defaultOptionForEmptySearchResult="defaultCountryOption"
        searchable
        closeOnClick
        useTranslate
      />
    </div>

    <!-- 3.1-3-1 (10) -->
    <business-kr
      v-if="national === DEFAULT_COUNTRY"
      :disabled="disableKrField"
      :isManual="isManual"
      :hasBizRegisNum="hasBizRegisNum"
      :bizCardInfo="bizCardInfo"
      :optionBusinessType="optionBusinessType"
      :hasRegistrationNumberInfo="hasRegistrationNumberInfo"
      @search="handleIndividualInfo"
    />

    <template v-else>
      <!-- 3.1-2-4-A -->
      <component
        :is="getRepresentativeComponent"
        :disabled="modeDisabled || (isEdit && !disableOtherNationField)"
      />

      <!-- 3.1-4-1-A -->
      <st-form-title
        :formTitle="$t('studio.business_bank_setting.individual.info_view')"
        required
        class="mt-32"
      />
      <div class="flex flex-col gap-8">
        <div class="flex items-center gap-4">
          <!-- 3.1-4-1-A (11-10)-->
          <div
            class="shrink-0 w-[15rem] text-sm font-regular leading-md text-on-surface-elevation-3"
          >
            {{ $t('studio.business_bank_setting.individual.info_view_b_name') }}
          </div>
          <div class="w-full">
            <input-text
              name="individual.businessInfo.name"
              :placeholder="
                $t('studio.business_bank_setting.individual.info_view_b_name_place_holder')
              "
              :rules="{
                required: $t('studio.business_bank_setting.individual.info_view_b_name_msg1'),
                max_length: {
                  length: 50,
                  message: $t('studio.business_bank_setting.individual.info_view_b_name_msg2', {
                    length: 50
                  }),
                  no_sp_character: true
                }
              }"
              :disabled="modeDisabled || (isEdit && !disableOtherNationField)"
            />
          </div>
        </div>

        <!-- 3.1-4-1-A (11-11)-->
        <div class="flex items-center gap-4">
          <div
            class="shrink-0 w-[15rem] text-sm font-regular leading-md text-on-surface-elevation-3"
          >
            {{ $t('studio.business_bank_setting.individual.info_view_r_name') }}
          </div>
          <div class="w-full">
            <input-text
              name="individual.businessInfo.ceoName"
              :placeholder="
                $t('studio.business_bank_setting.individual.info_view_r_name_non_kr_place_holder')
              "
              :rules="{
                required: $t(
                  'studio.business_bank_setting.individual.info_view_r_name_non_kr_msg1'
                ),
                regex: {
                  regex: COMMA_SEPARATED_NAMES_REGEX,
                  message: $t(
                    'studio.business_bank_setting.individual.info_view_r_name_non_kr_msg1'
                  )
                },
                max_length: {
                  length: 50,
                  message: $t('studio.business_bank_setting.individual.info_view_r_name_msg2', {
                    length: 50
                  })
                }
              }"
              :disabled="modeDisabled || (isEdit && !disableOtherNationField)"
            />
          </div>
        </div>

        <!-- 3.1-4-1-A (11-16)-->
        <div class="flex items-center gap-4">
          <div
            class="shrink-0 w-[15rem] text-sm font-regular leading-md text-on-surface-elevation-3"
          >
            {{ $t('studio.business_bank_setting.individual.busuness_no') }}
          </div>
          <div class="w-full">
            <input-text
              name="individual.businessInfo.registrationNo"
              :placeholder="$t('studio.business_bank_setting.individual.busuness_no_place_holder')"
              :rules="{
                required: $t('studio.business_bank_setting.individual.busuness_no_msg1'),
                number: true,
                max_length: {
                  length: 50,
                  message: $t('studio.common.def_key.exceed', { length: 50 })
                }
              }"
              :disabled="hasRegistrationNumberInfo && disableOtherNationField"
            />
          </div>
        </div>

        <!-- 3.1-4-1-A (11-17)-->
        <div class="flex items-center gap-4">
          <div
            class="shrink-0 w-[15rem] text-sm font-regular leading-md text-on-surface-elevation-3"
          >
            {{ $t('studio.business_bank_setting.individual.info_view_tax') }}
          </div>
          <div class="w-full">
            <input-text
              name="individual.businessInfo.taxId"
              :placeholder="
                $t('studio.business_bank_setting.individual.info_view_tax_place_holder')
              "
              :rules="{
                required: $t('studio.business_bank_setting.individual.info_view_tax_msg1'),
                max_length: {
                  length: 50,
                  message: $t('studio.common.def_key.exceed', { length: 50 })
                }
              }"
              :disabled="disableOtherNationField"
            />
          </div>
        </div>
      </div>
    </template>

    <!-- 3.1-3-1 (11-8) -->
    <st-form-title
      :formTitle="$t('studio.business_bank_setting.individual.question5')"
      required
      class="mt-32"
    />
    <div class="flex gap-8">
      <dropdown
        rules="required"
        name="individual.mobileCd"
        :options="countryOptions"
        :disabled="modeDisabled || (isEdit && !disableKrField && !disableOtherNationField)"
        :dropdownProps="{
          size: 'lg',
          variant: 'line',
          distance: 0,
          offset: [0, 0],
          placement: 'bottom-start',
          class: 'w-[18rem] shrink-0'
        }"
        closeOnClick
      >
        <template #default="{ option }">
          <s-dropdown-item :value="option.value">
            {{ `${option.extendedLabel} (${$t(option.label)})` }}
          </s-dropdown-item>
        </template>
        <template #dropdown-button="{ selectedOption }">
          {{ `${selectedOption.extendedLabel} (${$t(selectedOption.label)})` }}
        </template>
      </dropdown>
      <div class="w-full relative">
        <input-text
          name="individual.mobileNo"
          :placeholder="
            national === DEFAULT_COUNTRY
              ? $t('studio.business_bank_setting.individual.question5_kr_place_holder')
              : $t('studio.business_bank_setting.individual.question5_non_kr_place_holder')
          "
          :rules="{
            required: $t('studio.business_bank_setting.individual.select_mobile_no_msg1'),
            number: $t('studio.common.def_key.number_only_y'),
            max_length: {
              length: 15,
              message: $t('studio.common.def_key.exceed', { length: 15 })
            },
            no_sp_character: true
          }"
          :disabled="modeDisabled || (isEdit && !disableKrField && !disableOtherNationField)"
        />
      </div>
    </div>

    <!-- 3.1-3-1 (11-12) -->
    <st-form-title
      :formTitle="$t('studio.business_bank_setting.individual.b_email')"
      required
      class="mt-32"
    />
    <input-text
      name="individual.email"
      :placeholder="$t('studio.business_bank_setting.individual.b_email_place_holder')"
      :rules="{
        required: $t('studio.business_bank_setting.individual.b_email_msg1'),
        max_length: {
          length: 50,
          message: $t('studio.common.def_key.exceed', { length: 50 })
        },
        email: $t('studio.business_bank_setting.individual.b_email_msg2')
      }"
      :disabled="modeDisabled || (isEdit && !disableKrField && !disableOtherNationField)"
    />

    <!-- 3.1-3-1 (12) -->
    <upload-contract
      name="individual.uploadResidentName"
      :groupId="groupId"
      :contractNo="residentContractNo"
      :label="
        gdsInfo.nation === DEFAULT_NATION
          ? $t('studio.business_bank_setting.individual.upload_buisiness')
          : $t('studio.business_bank_setting.individual.info_passport_copy')
      "
      :fileDesc1="
        gdsInfo.nation === DEFAULT_NATION
          ? $t('studio.business_bank_setting.individual.upload_buisiness_guide')
          : $t('studio.business_bank_setting.individual.info_passport_attach_guide')
      "
      :fileDesc2="
        $t('studio.business_bank_setting.individual.upload_guide', {
          info: 'jpg,&nbsp png,&nbsp pdf &nbsp| &nbsp 20MB'
        })
      "
      :buttonText="$t('studio.business_bank_setting.individual.btn_attach')"
      :rules="{
        required: {
          value: true,
          showError: false,
          message:
            gdsInfo.nation === DEFAULT_NATION
              ? $t('studio.business_bank_setting.individual.upload_buisiness_req_msg')
              : $t('studio.business_bank_setting.individual.info_passport_copy_req_msg')
        },
        extension: {
          extensions: BUSINESS_IMAGE_EXTENSION,
          message: $t('studio.business_bank_setting.individual.btn_attach_alert1'),
          showError: false,
          errorViewType: ErrorViewTypes.POPUP
        },
        max_size: {
          size: (2 * TEN_MEGABYTES) / 1024,
          message: $t('studio.business_bank_setting.individual.btn_attach_alert2'),
          showError: false,
          errorViewType: ErrorViewTypes.POPUP
        },
        file_count: {
          max: 1,
          message: $t('studio.prj_prod.this_prod.edit_gamepreview_import_this_media_alert2'),
          showError: false,
          errorViewType: ErrorViewTypes.POPUP
        }
      }"
      :isDisabled="modeDisabled || (isEdit && !disableKrField && !disableOtherNationField)"
      @onUploadFile="handleUploadFile"
      @removeFile="removeUploadedFile"
    />
    <st-error-message v-if="hasResidentNameRequiredError" :name="'individual.uploadResidentName'" />
  </st-box>

  <!-- 3.1-3-2 -->
  <st-box class="mt-24">
    <!-- 3.1-3-2 (13)-->
    <st-form-title :formTitle="$t('studio.business_bank_setting.individual.question6')" required />
    <p class="text-xs leading-xs text-placeholder font-regular mt-4">
      {{ $t('studio.business_bank_setting.individual.question6_guide') }}
    </p>
    <radio-group
      name="individual.gameType"
      rules="required"
      :options="optionGameType"
      :disabled="modeDisabled || (isEdit && !disableKrField && !disableOtherNationField)"
      size="sm"
      :optionProps="{
        size: 'sm',
        align: 'middle'
      }"
      class="flex flex-wrap gap-x-24 mt-12"
    />

    <!-- 3.1-3-2 (14)-->
    <st-form-title
      :formTitle="$t('studio.business_bank_setting.individual.question7')"
      class="mt-32"
    />
    <p class="text-xs leading-xs text-placeholder font-regular mt-4">
      {{ $t('studio.business_bank_setting.individual.question7_guide') }}
    </p>
    <div class="mt-12 flex flex-col gap-16">
      <checkbox-group
        name="individual.subBizInfo"
        :options="optGameProd"
        :disabled="modeDisabled || (isEdit && !disableKrField && !disableOtherNationField)"
        class="flex flex-col gap-8"
        size="sm"
        :optionProps="{ align: 'middle' }"
      >
        <template #default="{ value, label, index }">
          <s-checkbox :value="value" size="sm" align="middle">
            {{ $t(label) }}
          </s-checkbox>
          <div v-if="subBizInfo.includes(value as string)" class="ml-24">
            <input-text
              :name="`individual.businessInfo.${value}Number`"
              :placeholder="$t(optGameProd[index].placeHolder)"
              :disabled="(modeDisabled || isEdit) && !disableKrField && !disableOtherNationField"
              :rules="{
                required: optGameProd[index].inputRequireKey,
                max_length: {
                  length: value === SUB_BIZ_INFO.MAIL_ORDER ? 20 : 50,
                  message: $t('studio.common.def_key.exceed', {
                    length: value === SUB_BIZ_INFO.MAIL_ORDER ? 20 : 50
                  })
                }
              }"
            />
            <upload-contract
              :name="`individual.businessInfo.${value}Contract`"
              :groupId="groupId"
              :contractNo="
                value === SUB_BIZ_INFO.GAME_PRODUCTION
                  ? gameDevelopPublishRegistrationContractNo
                  : value === SUB_BIZ_INFO.MAIL_ORDER
                    ? telecommunicationRegistrationContractNo
                    : koreaBusinessRegistrationContractNo
              "
              :fileDesc1="$t(optGameProd[index].uploaderGuide)"
              :fileDesc2="
                $t(optGameProd[index].guide, {
                  info: 'jpg,&nbsp png,&nbsp pdf &nbsp| &nbsp 20MB'
                })
              "
              :buttonText="t('studio.business_bank_setting.individual.btn_attach')"
              :rules="{
                required: {
                  value: true,
                  message: $t(optGameProd[index].uploadRequireKey),
                  showError: false
                },
                extension: {
                  extensions: BUSINESS_IMAGE_EXTENSION,
                  message: $t('studio.business_bank_setting.individual.btn_attach_alert1'),
                  showError: false,
                  errorViewType: ErrorViewTypes.POPUP
                },
                max_size: {
                  size: (2 * TEN_MEGABYTES) / 1024,
                  message: $t('studio.business_bank_setting.individual.btn_attach_alert2'),
                  showError: false,
                  errorViewType: ErrorViewTypes.POPUP
                },
                file_count: {
                  max: 1,
                  message: $t(
                    'studio.prj_prod.this_prod.edit_gamepreview_import_this_media_alert2'
                  ),
                  showError: false,
                  errorViewType: ErrorViewTypes.POPUP
                }
              }"
              :isDisabled="modeDisabled || (isEdit && !disableKrField && !disableOtherNationField)"
              classFile="mt-8"
              @removeFile="removeContractFile(value as string)"
              @onUploadFile="(res: BusinessBankContract) => handleUploadContractNo(res, value as string)"
            />
          </div>
        </template>
      </checkbox-group>
    </div>
  </st-box>

  <managers-info
    :disabled="modeDisabled"
    :options="countryOptions"
    :managerType="MANAGER_TYPE.RATING"
  />

  <!-- contract info / 3.1-2-5 -->
  <managers-info
    :disabled="modeDisabled"
    :options="countryOptions"
    :managerType="MANAGER_TYPE.CONTRACT"
  />
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useFieldError, useFieldValue, useIsFieldValid, useSetFieldValue } from 'vee-validate';
import { computed, defineAsyncComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import { checkBusinessRegistrationLicenseApi } from '@/apis/business-bank-info.api';
import BusinessKr from '@/components/business-bank-info/business-info/have-license/representative-kr.vue';
import LicenseCheck from '@/components/business-bank-info/business-info/license-check.vue';
import ManagersInfo from '@/components/business-bank-info/business-info/no-license/managers-info.vue';
import UploadContract from '@/components/business-bank-info/common/upload-file.vue';
import StBox from '@/components/common/st-box.vue';
import StFormTitle from '@/components/common/st-form-title.vue';
import CheckboxGroup from '@/components/validation/checkbox-group.vue';
import Dropdown from '@/components/validation/dropdown.vue';
import InputText from '@/components/validation/input-text.vue';
import RadioGroup from '@/components/validation/radio-group.vue';
import StErrorMessage from '@/components/validation/st-error-message.vue';
import { showConfirm } from '@/composables/useDialog';
import {
  BUSINESS_TYPE,
  GAME_TYPE,
  MANAGER_TYPE,
  SUB_BIZ_INFO
} from '@/constants/business-bank.const';
import { DEFAULT_NATION } from '@/constants/common.const';
import { DEFAULT_COUNTRY } from '@/constants/cookie.const';
import { BUSINESS_IMAGE_EXTENSION, TEN_MEGABYTES } from '@/constants/file.const';
import { DEFAULT_COUNTRY_CODE_FOR_SEARCH_COUNTRY } from '@/constants/national-list.const';
import { COMMA_SEPARATED_NAMES_REGEX } from '@/constants/regex.const';
import { NationsAddress } from '@/enums/common.enum';
import { ErrorViewTypes, RuleNames } from '@/enums/validation.enum';
import { useAppStore } from '@/stores/app.store';
import { useCountryStore } from '@/stores/country.store';
import type { CompanyInfoModel } from '@/types/business-info/business-model.type';
import type { BusinessBankContract } from '@/types/common/file.type';
import type { FormOption } from '@/types/common/form.type';

const { t } = useI18n();

const props = defineProps<{
  modeDisabled?: boolean;
  isEdit?: boolean;
}>();

const route = useRoute();

const groupId = route.query.group_id as string;

const countryStore = useCountryStore();
const { gdsInfo } = storeToRefs(useAppStore());

const national = useFieldValue<string>('individual.businessInfo.nation');
const krBizRegisNum = useFieldValue<string>('individual.businessInfo.registrationNo');
const isKrBizRegisNum = useIsFieldValid('individual.businessInfo.registrationNo');
const companyName = useFieldValue<string>('individual.businessInfo.name');
const subBizInfo = useFieldValue<string[]>('individual.subBizInfo');

const companyInfo = useFieldValue<CompanyInfoModel>('individual.businessInfo');

const setSearchNo = useSetFieldValue<string>('individual.businessInfo.searchNo');

const uploadResident = useFieldValue<BusinessBankContract[]>('individual.uploadResident');

const setUploadResident = useSetFieldValue<BusinessBankContract[]>('individual.uploadResident');
const setResidentName = useSetFieldValue<string>('individual.uploadResidentName');

const setKrBizCategory = useSetFieldValue<string>('individual.businessInfo.subBizDescription');
const setKrCompanyName = useSetFieldValue<string>('individual.businessInfo.name');
const setKrCeoName = useSetFieldValue<string>('individual.businessInfo.ceoName');
const setKrTypeCompany = useSetFieldValue<string>('individual.businessInfo.type');
const setKrTypeBiz = useSetFieldValue<string>('individual.businessInfo.businessType');
const setKrBizAddress = useSetFieldValue<string>('individual.businessInfo.detailAddress');

const uploadResidentNameError = useFieldError('individual.uploadResidentName');

const koreaBusinessRegistrationContractNo = useFieldValue<number[]>(
  'individual.businessInfo.koreaBusinessRegistrationContractNo'
);
const gameDevelopPublishRegistrationContractNo = useFieldValue<number[]>(
  'individual.businessInfo.gameDevelopPublishRegistrationContractNo'
);
const telecommunicationRegistrationContractNo = useFieldValue<number[]>(
  'individual.businessInfo.telecommunicationRegistrationContractNo'
);

const setKoreaBusinessRegistrationContractNo = useSetFieldValue<number[]>(
  'individual.businessInfo.koreaBusinessRegistrationContractNo'
);
const setGameDevelopPublishRegistrationContractNo = useSetFieldValue<number[]>(
  'individual.businessInfo.gameDevelopPublishRegistrationContractNo'
);
const setTelecommunicationRegistrationContractNo = useSetFieldValue<number[]>(
  'individual.businessInfo.telecommunicationRegistrationContractNo'
);

const setBusinessRegistrationContractNo = useSetFieldValue<number[]>(
  'individual.businessInfo.businessRegistrationContractNo'
);

const setGameDevelopPublishRegistrationContract = useSetFieldValue(
  'individual.businessInfo.gameDevelopPublishRegistrationContract'
);
const setTelecommunicationRegistrationContract = useSetFieldValue(
  'individual.businessInfo.telecommunicationRegistrationContract'
);
const setKoreaBusinessRegistrationContract = useSetFieldValue(
  'individual.businessInfo.koreaBusinessRegistrationContract'
);

const { countryOptions } = storeToRefs(countryStore);
const defaultCountryOption = countryOptions.value.find(
  (item: FormOption) => item.value === DEFAULT_COUNTRY_CODE_FOR_SEARCH_COUNTRY
);

const isManual = ref<boolean>(false);
const hasRegistrationNumberInfo = ref(false);

const residentContractNo = computed(() => {
  return uploadResident.value.map((value: BusinessBankContract) => value.contractNo);
});

const hasBizRegisNum = computed(() => !!krBizRegisNum.value && !!companyName.value);

const disableKrField = computed(() => {
  return (props.isEdit && national.value === DEFAULT_COUNTRY) || props.modeDisabled;
});

const disableOtherNationField = computed(() => {
  return (props.isEdit && national.value !== DEFAULT_COUNTRY) || props.modeDisabled;
});

// 3.1-4-2 (14)
const optGameProd = computed(() => {
  if (national.value !== DEFAULT_COUNTRY) {
    return [
      {
        value: SUB_BIZ_INFO.GAME_PRODUCTION, // 'gameProduction',
        label: 'studio.business_bank_setting.individual.question6_select1_kr',
        guide: 'studio.business_bank_setting.individual.upload_guide',
        uploaderGuide: 'studio.business_bank_setting.individual.game_production_no_kr_guide',
        placeHolder: 'studio.business_bank_setting.individual.game_production_no_place_holder_kr',
        inputRequireKey: 'studio.business_bank_setting.individual.game_production_no_msg1_kr',
        uploadRequireKey: 'studio.business_bank_setting.individual.game_production_no_kr_guide'
      },
      {
        value: SUB_BIZ_INFO.MAIL_ORDER, // 'mailOrder',
        guide: 'studio.business_bank_setting.individual.upload_guide',
        label: 'studio.business_bank_setting.individual.question6_select2_kr',
        uploaderGuide: 'studio.business_bank_setting.individual.telecom_sales_no_kr_guide',
        placeHolder: 'studio.business_bank_setting.individual.telecom_sales_no_place_holder_kr',
        inputRequireKey: 'studio.business_bank_setting.individual.telecom_sales_no_msg1_kr',
        uploadRequireKey: 'studio.business_bank_setting.individual.telecom_sales_no_kr_guide'
      },
      {
        value: SUB_BIZ_INFO.KW_BIZ_REGIS_CER, // 'kwBizRegisCer',
        guide: 'studio.business_bank_setting.individual.upload_guide',
        label: 'studio.business_bank_setting.individual.question6_select3_kr',
        uploaderGuide: 'studio.business_bank_setting.individual.game_business_no_kr_guide',
        placeHolder: 'studio.business_bank_setting.individual.game_business_no_place_holder_kr',
        inputRequireKey: 'studio.business_bank_setting.individual.game_business_no_msg1_kr',
        uploadRequireKey: 'studio.business_bank_setting.individual.game_business_no_kr_guide'
      }
    ];
  }
  return optionGameProduction;
});

// <!-- 3.1-3-1 (11) -->
const bizCardInfo = computed(() => {
  if (!companyInfo.value) {
    return [];
  }
  return [
    {
      label: 'studio.business_bank_setting.individual.info_view_type',
      value:
        companyInfo.value.businessType === BUSINESS_TYPE.PERSONAL
          ? t('studio.business_bank_setting.list_biz_type_indiv')
          : companyInfo.value.businessType === BUSINESS_TYPE.INDIVIDUAL_BUSINESS
            ? t('studio.business_bank_setting.list_biz_type_indiv_biz_owner')
            : t('studio.business_bank_setting.list_biz_type_business')
    },
    {
      label: 'studio.business_bank_setting.individual.info_view_b_name',
      value: companyInfo.value.name
    },
    {
      label: 'studio.business_bank_setting.individual.info_view_r_name',
      value: companyInfo.value.ceoName
    },
    {
      label: 'studio.business_bank_setting.individual.info_view_b_type',
      value: companyInfo.value.type
    },
    {
      label: 'studio.business_bank_setting.individual.info_view_b_items',
      value: companyInfo.value.subBizDescription
    },
    {
      label: 'studio.business_bank_setting.individual.info_view_adress',
      value: companyInfo.value.detailAddress ?? ''
    }
  ];
});

const optionGameType: FormOption[] = [
  {
    value: GAME_TYPE.DEVELOPER, // 'developer',
    label: 'studio.business_bank_setting.individual.question6_select1'
  },
  {
    value: GAME_TYPE.BROKERAGE, // 'publisher',
    label: 'studio.business_bank_setting.individual.question6_select2'
  },
  {
    value: GAME_TYPE.NONE, // 'other',
    label: 'studio.business_bank_setting.individual.question6_select3'
  }
];

// 3.1-3-1-A (11-2)
const optionBusinessType: FormOption[] = [
  {
    value: BUSINESS_TYPE.INDIVIDUAL_BUSINESS,
    label: 'studio.business_bank_setting.individual.info_view_type1'
  },
  {
    value: BUSINESS_TYPE.CORPORATION_BUSINESS,
    label: 'studio.business_bank_setting.individual.info_view_type2'
  }
];

// 3.1-3-2 (14)
const optionGameProduction = [
  {
    value: SUB_BIZ_INFO.GAME_PRODUCTION,
    label: 'studio.business_bank_setting.individual.question7_select1',
    uploaderGuide: 'studio.business_bank_setting.individual.game_production_no_guide',
    guide: 'studio.business_bank_setting.individual.upload_guide',
    placeHolder: 'studio.business_bank_setting.individual.game_production_no_place_holder',
    inputRequireKey: 'studio.business_bank_setting.individual.game_production_no_msg1',
    uploadRequireKey: 'studio.business_bank_setting.individual.game_production_no_guide_req_msg'
  },
  {
    value: SUB_BIZ_INFO.MAIL_ORDER,
    label: 'studio.business_bank_setting.individual.question7_select2',
    uploaderGuide: 'studio.business_bank_setting.individual.telecom_sales_no_guide',
    guide: 'studio.business_bank_setting.individual.upload_guide',
    placeHolder: 'studio.business_bank_setting.individual.telecom_sales_no_place_holder',
    inputRequireKey: 'studio.business_bank_setting.individual.telecom_sales_no_msg1',
    uploadRequireKey: 'studio.business_bank_setting.individual.telecom_sales_no_guide_req_msg'
  }
];

const getRepresentativeComponent = computed(() => {
  switch (national.value) {
    case NationsAddress.JAPAN:
    case NationsAddress.TAIWAN:
    case NationsAddress.VIETNAM:
      return defineAsyncComponent(
        () =>
          import(
            '@/components/business-bank-info/business-info/representative/representative-ja.vue'
          )
      );
    default:
      return defineAsyncComponent(
        () =>
          import(
            '@/components/business-bank-info/business-info/representative/representative-other.vue'
          )
      );
  }
});

const showManualKrBizRegisNum = async () => {
  const result = await showConfirm({
    content: t('studio.business_bank_setting.individual.enter_no_msg5'),
    confirmLabel: t('studio.business_bank_setting.individual.enter_no_msg5_close'),
    cancelLabel: t('studio.business_bank_setting.individual.enter_no_msg5_manual')
  });
  if (!result) {
    isManual.value = true;
  }
};

const handleIndividualInfo = async () => {
  try {
    if (!isKrBizRegisNum.value) {
      return;
    }
    const res = await checkBusinessRegistrationLicenseApi(groupId, krBizRegisNum.value);
    if (res) {
      setKrBizAddress(res.businessInfo.address);
      setKrTypeBiz(res.businessInfo.businessType);
      setKrTypeCompany(res.businessInfo.type);
      setKrCompanyName(res.businessInfo.companyName);
      setKrCeoName(res.businessInfo.representative);
      setKrBizCategory(res.businessInfo.category);
      setSearchNo(res.searchNo);
    } else {
      showManualKrBizRegisNum();
    }
  } catch {
    showManualKrBizRegisNum();
  }
};

const handleUploadFile = (res: BusinessBankContract) => {
  setBusinessRegistrationContractNo([res.contractNo]);
  setUploadResident([res]);
};

const removeUploadedFile = () => {
  setUploadResident([]);
  setResidentName('');
};

const removeContractFile = (value: string) => {
  switch (value) {
    case SUB_BIZ_INFO.GAME_PRODUCTION:
      setGameDevelopPublishRegistrationContract([]);
      break;
    case SUB_BIZ_INFO.MAIL_ORDER:
      setTelecommunicationRegistrationContract([]);
      break;
    case SUB_BIZ_INFO.KW_BIZ_REGIS_CER:
      setKoreaBusinessRegistrationContract([]);
      break;
    default:
      break;
  }
};

const handleUploadContractNo = (res: BusinessBankContract, value: string) => {
  switch (value) {
    case SUB_BIZ_INFO.GAME_PRODUCTION:
      setGameDevelopPublishRegistrationContractNo([res.contractNo]);
      break;
    case SUB_BIZ_INFO.MAIL_ORDER:
      setTelecommunicationRegistrationContractNo([res.contractNo]);
      break;
    case SUB_BIZ_INFO.KW_BIZ_REGIS_CER:
      setKoreaBusinessRegistrationContractNo([res.contractNo]);
      break;
    default:
      break;
  }
};

const hasResidentNameRequiredError = computed(() => {
  return (
    !!uploadResidentNameError.value &&
    JSON.parse(uploadResidentNameError.value).rule === RuleNames.REQUIRED
  );
});

const init = () => {
  hasRegistrationNumberInfo.value = !!krBizRegisNum.value;
};

init();
</script>
