<template>
  <st-box>
    <div class="mb-32">
      <st-form-title
        :formTitle="$t('studio.transfer.mgmt_history.detail_pg_info1_grp_name')"
        required
      />
      <input-text
        name="groupTransfer.groupName"
        :placeholder="$t('studio.transfer.mgmt_history.detail_pg_info1_grp_name_place_holder')"
        :rules="{
          required: {
            value: true,
            message: $t('studio.transfer.mgmt_history.detail_pg_info1_grp_name_req_msg')
          },
          max_length: {
            length: 60,
            message: $t('studio.common.def_key.exceed', { length: 60 })
          }
        }"
        :countable="true"
        maxLength="60"
        :disabled="modeDisabled"
      />
    </div>
    <!-- 3.1-1-1 -->
    <license-check :disabled="modeDisabled || isEdit" />

    <!-- 3.1-2-2 (3) -->
    <st-form-title
      :formTitle="$t('studio.business_bank_setting.individual.question2')"
      required
      class="mt-32"
    />
    <div class="relative">
      <dropdown
        rules="required"
        name="individual.nationality"
        :options="countryOptions"
        :disabled="modeDisabled || isEdit"
        :dropdownProps="{
          size: 'lg',
          variant: 'line',
          distance: 0,
          offset: [0, 0],
          placement: 'bottom-start',
          class: 'w-full'
        }"
        :defaultOptionForEmptySearchResult="defaultCountryOption"
        searchable
        closeOnClick
        useTranslate
      />
    </div>
    <checkbox
      name="individual.countryTaxEqualYn"
      class="mt-8"
      :disabled="modeDisabled || isEdit"
      :optionProps="{
        size: 'sm',
        align: 'middle'
      }"
    >
      {{ $t('studio.business_bank_setting.individual.question2_checkmsg') }}
    </checkbox>
    <!-- 3.1-2-3 -->
    <template v-if="!countryTaxEqualYn">
      <st-form-title
        :formTitle="$t('studio.business_bank_setting.individual.question3')"
        required
        class="mt-32"
      />
      <div class="relative">
        <dropdown
          rules="required"
          name="individual.taxCountry"
          :options="countryOptions"
          :disabled="modeDisabled || isEdit"
          :dropdownProps="{
            size: 'lg',
            variant: 'line',
            distance: 0,
            offset: [0, 0],
            placement: 'bottom-start',
            class: 'w-full'
          }"
          :defaultOptionForEmptySearchResult="defaultCountryOption"
          searchable
          closeOnClick
          useTranslate
        />
      </div>
      <p class="text-sm leading-md text-on-surface-elevation-4 font-regular mt-8">
        {{ $t('studio.business_bank_setting.individual.question3_msg1') }}
      </p>
    </template>

    <!-- 3.1-2-2 (4) -->
    <st-form-title
      :formTitle="$t('studio.business_bank_setting.individual.input_name')"
      required
      class="mt-32"
    />
    <input-text
      name="individual.businessInfo.ceoName"
      :placeholder="$t('studio.business_bank_setting.individual.input_name_place_holder')"
      :rules="{
        required: $t('studio.business_bank_setting.individual.input_name_msg1'),
        max_length: {
          length: 50,
          message: $t('studio.common.def_key.exceed', { length: 50 })
        }
      }"
      :disabled="modeDisabled"
    />

    <!-- 3.1-2-2 (5) -->
    <st-form-title
      :formTitle="$t('studio.business_bank_setting.individual.select_gender')"
      required
      class="mt-32"
    />
    <radio-group
      name="individual.gender"
      size="sm"
      :rules="{
        required: $t('studio.business_bank_setting.individual.select_gender_required_msg')
      }"
      :options="genders"
      :disabled="modeDisabled"
      :optionProps="{
        size: 'sm',
        align: 'middle'
      }"
      class="flex flex-wrap gap-x-24 mt-12"
    />

    <!-- 3.1-2-2 (6) -->
    <st-form-title
      :formTitle="$t('studio.business_bank_setting.individual.select_birth')"
      required
      class="mt-32"
    />

    <input-text
      name="individual.inputBirth"
      :placeholder="$t('studio.business_bank_setting.individual.select_birth_place_holder')"
      :rules="{
        required: $t('studio.business_bank_setting.individual.select_birth_msg1'),
        regex: {
          pattern: /^\d{4}-([0][1-9]|1[0-2])-([0-2][0-9]|3[01])$/,
          message: $t('studio.business_bank_setting.individual.select_birth_wrong_format_msg')
        }
      }"
      :disabled="isBirthDateDisabled"
      :maxLength="'10'"
      :clearable="true"
      :countable="false"
      @input="handleBirthDateInput"
      @keydown="handleKeyDown"
      @paste.prevent
    />

    <!-- 3.1-2-2 (7) -->
    <st-form-title
      :formTitle="$t('studio.business_bank_setting.individual.select_mobile_no')"
      required
      class="mt-32"
    />
    <div class="flex gap-8">
      <dropdown
        rules="required"
        name="individual.mobileCd"
        :options="countryOptions"
        :disabled="modeDisabled"
        :dropdownProps="{
          size: 'lg',
          variant: 'line',
          distance: 0,
          offset: [0, 0],
          placement: 'bottom-start',
          class: 'w-[18rem] shrink-0'
        }"
        closeOnClick
      >
        <template #default="{ option }">
          <s-dropdown-item :value="option.value">
            {{ `${option.extendedLabel} (${$t(option.label)})` }}
          </s-dropdown-item>
        </template>
        <template #dropdown-button="{ selectedOption }">
          {{ `${selectedOption.extendedLabel} (${$t(selectedOption.label)})` }}
        </template>
      </dropdown>
      <div class="w-full relative">
        <input-text
          name="individual.mobileNo"
          :placeholder="$t('studio.business_bank_setting.individual.select_mobile_no_place_holder')"
          :rules="{
            required: $t('studio.business_bank_setting.individual.select_mobile_no_msg1'),
            number: $t('studio.common.def_key.number_only_y'),
            max_length: {
              length: 15,
              message: $t('studio.common.def_key.exceed', { length: 15 })
            },
            no_sp_character: true
          }"
          :disabled="modeDisabled"
        />
      </div>
    </div>

    <!-- 3.1-2-2 (8) -->
    <st-form-title
      :formTitle="$t('studio.business_bank_setting.individual.select_ceo_email')"
      required
      class="mt-32"
    />
    <input-text
      name="individual.email"
      :placeholder="$t('studio.business_bank_setting.individual.select_ceo_email_place_holder')"
      :rules="{
        required: $t('studio.business_bank_setting.individual.select_ceo_email_msg1'),
        max_length: {
          length: 50,
          message: $t('studio.common.def_key.exceed', { length: 50 })
        },
        email: $t('studio.business_bank_setting.individual.select_ceo_email_msg2')
      }"
      :disabled="modeDisabled"
    />

    <!-- 3.1-2-4 (8)-->
    <st-form-title
      :formTitle="$t('studio.business_bank_setting.individual.info_view_b_adress')"
      required
      class="mt-32"
    />
    <div class="relative">
      <dropdown
        rules="required"
        name="individual.businessInfo.nation"
        :options="countryOptions"
        :disabled="modeDisabled"
        :dropdownProps="{
          size: 'lg',
          variant: 'line',
          distance: 0,
          offset: [0, 0],
          placement: 'bottom-start',
          class: 'w-full'
        }"
        :defaultOptionForEmptySearchResult="defaultCountryOption"
        searchable
        useTranslate
        closeOnClick
      />
    </div>

    <!-- 3.1-2-4 -->
    <representative-kr
      v-if="national === DEFAULT_COUNTRY"
      :disabled="modeDisabled"
      @search="sendPostalCode"
    />

    <!-- 3.1-2-4-A -->
    <component :is="getRepresentativeComponent" v-else :disabled="modeDisabled" />

    <!-- 3.1-2-4 (9) -->
    <upload-contract
      name="individual.uploadResidentName"
      :groupId="groupId"
      :contractNo="residentContractNo"
      :label="
        countryAndTaxCountryKorea
          ? $t('studio.business_bank_setting.individual.upload_resident')
          : $t('studio.business_bank_setting.individual.info_passport_copy')
      "
      :fileDesc1="
        countryAndTaxCountryKorea
          ? $t('studio.business_bank_setting.individual.upload_resident_req_msg')
          : $t('studio.business_bank_setting.individual.info_passport_copy_req_msg')
      "
      :fileDesc2="
        $t('studio.business_bank_setting.individual.upload_guide', {
          info: 'jpg,&nbsp png,&nbsp pdf &nbsp| &nbsp 20MB'
        })
      "
      :buttonText="$t('studio.business_bank_setting.individual.btn_attach')"
      :rules="{
        required: {
          value: true,
          showError: false,
          message: countryAndTaxCountryKorea
            ? $t('studio.business_bank_setting.individual.upload_resident_req_msg')
            : $t('studio.business_bank_setting.individual.info_passport_copy_req_msg')
        },
        extension: {
          extensions: BUSINESS_IMAGE_EXTENSION,
          message: $t('studio.business_bank_setting.individual.btn_attach_alert1'),
          showError: false,
          errorViewType: ErrorViewTypes.POPUP
        },
        max_size: {
          size: (2 * TEN_MEGABYTES) / 1024,
          message: $t('studio.business_bank_setting.individual.btn_attach_alert2'),
          showError: false,
          errorViewType: ErrorViewTypes.POPUP
        },
        file_count: {
          max: 1,
          message: $t('studio.prj_prod.this_prod.edit_gamepreview_import_this_media_alert2'),
          showError: false,
          errorViewType: ErrorViewTypes.POPUP
        }
      }"
      :isDisabled="modeDisabled"
      @onUploadFile="handleUploadFile"
      @removeFile="removeUploadedFile"
    />

    <st-error-message v-if="hasResidentNameRequiredError" :name="'individual.uploadResidentName'" />
  </st-box>
  <!-- review info / 3.1-2-5 -->
  <managers-info
    :disabled="modeDisabled"
    :options="countryOptions"
    :managerType="MANAGER_TYPE.RATING"
  />

  <!-- contract info / 3.1-2-5 -->
  <managers-info
    :disabled="modeDisabled"
    :options="countryOptions"
    :managerType="MANAGER_TYPE.CONTRACT"
  />
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useFieldError, useFieldValue, useSetFieldValue } from 'vee-validate';
import { computed, defineAsyncComponent, ref } from 'vue';
import { useRoute } from 'vue-router';

import LicenseCheck from '@/components/business-bank-info/business-info/license-check.vue';
import ManagersInfo from '@/components/business-bank-info/business-info/no-license/managers-info.vue';
import RepresentativeKr from '@/components/business-bank-info/business-info/no-license/representative-kr.vue';
import UploadContract from '@/components/business-bank-info/common/upload-file.vue';
import StBox from '@/components/common/st-box.vue';
import StFormTitle from '@/components/common/st-form-title.vue';
import Checkbox from '@/components/validation/checkbox.vue';
import Dropdown from '@/components/validation/dropdown.vue';
import InputText from '@/components/validation/input-text.vue';
import RadioGroup from '@/components/validation/radio-group.vue';
import StErrorMessage from '@/components/validation/st-error-message.vue';
import { MANAGER_TYPE } from '@/constants/business-bank.const';
import { DEFAULT_COUNTRY } from '@/constants/cookie.const';
import { BUSINESS_IMAGE_EXTENSION, TEN_MEGABYTES } from '@/constants/file.const';
import { DEFAULT_COUNTRY_CODE_FOR_SEARCH_COUNTRY } from '@/constants/national-list.const';
import { Gender, NationsAddress } from '@/enums/common.enum';
import { ErrorViewTypes, RuleNames } from '@/enums/validation.enum';
import { useCountryStore } from '@/stores/country.store';
import type { DaumPostcodeCompleteResult } from '@/types/common/common.type';
import type { BusinessBankContract } from '@/types/common/file.type';
import type { FormOption } from '@/types/common/form.type';

const props = defineProps<{
  modeDisabled?: boolean;
  isEdit?: boolean;
}>();

const emits = defineEmits<{
  postcode: [v: DaumPostcodeCompleteResult];
}>();

const route = useRoute();

const groupId = route.query.group_id as string;

const countryStore = useCountryStore();

// Get the current values of gender and birth date
// const genderValue = useFieldValue<string>('individual.gender');
const birthDateValue = useFieldValue<string>('individual.inputBirth');

const initialBirthDateValue = ref(birthDateValue.value);

const isBirthDateDisabled = computed(() => {
  return props.modeDisabled || (initialBirthDateValue.value !== null &&
    initialBirthDateValue.value !== undefined &&
    initialBirthDateValue.value !== '');
});

const nationality = useFieldValue<string>('individual.nationality');
const national = useFieldValue<string>('individual.businessInfo.nation');
const qPostCode = useFieldValue<string>('individual.businessInfo.zipCode');
const countryTaxEqualYn = useFieldValue<string[]>('individual.countryTaxEqualYn');
const setQPostCode = useSetFieldValue<string>('individual.businessInfo.zipCode');
const addr = useSetFieldValue<string>('individual.businessInfo.address');
const fullAddr = useSetFieldValue<string>('individual.businessInfo.fullAddress');
const taxCountry = useFieldValue<string>('individual.taxCountry');

const uploadResident = useFieldValue<BusinessBankContract[]>('individual.uploadResident');

const setUploadResident = useSetFieldValue<BusinessBankContract[]>('individual.uploadResident');
const setResidentName = useSetFieldValue<string>('individual.uploadResidentName');

const { countryOptions } = storeToRefs(countryStore);
const defaultCountryOption = countryOptions.value.find(
  (item: FormOption) => item.value === DEFAULT_COUNTRY_CODE_FOR_SEARCH_COUNTRY
);

const residentContractNo = computed(() => {
  return uploadResident.value.map((value: BusinessBankContract) => value.contractNo);
});

const genders: FormOption[] = [
  { label: 'studio.business_bank_setting.individual.select_gender_male', value: Gender.MALE },
  { label: 'studio.business_bank_setting.individual.select_gender_female', value: Gender.FEMALE }
];

const countryAndTaxCountryKorea = computed(() => {
  if (nationality.value !== DEFAULT_COUNTRY) {
    return false;
  }

  if (!countryTaxEqualYn.value && taxCountry.value !== DEFAULT_COUNTRY) {
    return false;
  }

  return true;
});

const getRepresentativeComponent = computed(() => {
  switch (national.value) {
    case NationsAddress.JAPAN:
    case NationsAddress.TAIWAN:
    case NationsAddress.VIETNAM:
      return defineAsyncComponent(
        () =>
          import(
            '@/components/business-bank-info/business-info/representative/representative-ja.vue'
          )
      );
    default:
      return defineAsyncComponent(
        () =>
          import(
            '@/components/business-bank-info/business-info/representative/representative-other.vue'
          )
      );
  }
});

const sendPostalCode = () => {
  // eslint-disable-next-line no-undef
  new daum.Postcode({
    oncomplete: function(dataDaum: DaumPostcodeCompleteResult) {
      addr(dataDaum.roadAddress);
      fullAddr(dataDaum.jibunAddress);
      setQPostCode(dataDaum.zonecode);
      emits('postcode', dataDaum);
    }
  }).open({
    q: qPostCode.value
  });
};

const handleUploadFile = (res: BusinessBankContract) => {
  setUploadResident([res]);
};

const removeUploadedFile = () => {
  setUploadResident([]);
  setResidentName('');
};

const handleBirthDateInput = (e: Event) => {
  const inputEvent = e as InputEvent;
  const input = e.target as HTMLInputElement;
  const cursorPosition = input.selectionStart;

  if (
    inputEvent.inputType === 'deleteContentBackward' ||
    inputEvent.inputType === 'deleteContentForward'
  ) {
    return;
  }

  let numbers = input.value.replace(/[^\d]/g, '');
  if (numbers.length > 8) {
    numbers = numbers.slice(0, 8);
  }

  // Format YYYY-MM-DD
  let formattedValue = '';
  const year = numbers.slice(0, 4);
  const month = numbers.slice(4, 6);
  const day = numbers.slice(6, 8);

  formattedValue = year;
  if (year) {
    formattedValue = year;
    if (year.length === 4) {
      formattedValue += '-';
      if (month) {
        formattedValue += month;
        if (month.length === 2) {
          formattedValue += '-';
          if (day) {
            formattedValue += day;
          }
        }
      }
    }
  }

  input.value = formattedValue;

  let newPosition = cursorPosition;
  if (cursorPosition === 4) {
    newPosition = 5;
  }
  if (cursorPosition === 7) {
    newPosition = 8;
  }

  input.setSelectionRange(newPosition, newPosition);
};

const handleKeyDown = (e: KeyboardEvent) => {
  if (
    e.key === 'Backspace' ||
    e.key === 'Delete' ||
    e.key === 'ArrowLeft' ||
    e.key === 'ArrowRight' ||
    e.key === 'Tab'
  ) {
    return;
  }

  if (!/^\d$/.test(e.key)) {
    e.preventDefault();
    e.stopPropagation();
    return false;
  }
};

const uploadResidentNameError = useFieldError('individual.uploadResidentName');

const hasResidentNameRequiredError = computed(() => {
  return (
    !!uploadResidentNameError.value &&
    JSON.parse(uploadResidentNameError.value).rule === RuleNames.REQUIRED
  );
});
</script>
