<template>
  <div class="max-w-[96rem] mx-auto">
    <h2 class="font-bold text-5xl leading-4xl text-on-surface-elevation-1 mb-16">
      {{ $t('studio.transfer.mgmt_history.detail_pg_tittle') }}
    </h2>

    <ul class="mb-8 text-sm font-regular leading-sm text-on-surface-elevation-3 flex flex-col">
      <safe-html :html="$t('studio.transfer.mgmt_history.detail_pg_guide1')" /><br />
      <safe-html :html="$t('studio.transfer.mgmt_history.detail_pg_guide2')" /><br />
      <safe-html :html="$t('studio.transfer.mgmt_history.detail_pg_guide3')" />
    </ul>

    <transfer-business-register :disabled="isDisabled" :isEdit="isEdit" @onSubmit="onSubmit" />
  </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { useForm } from 'vee-validate';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import { definePageMeta, goToLoginPage } from '@/.nuxt/imports';
import { approveMigrationGroupApi, fetchDetailGroupMigrationApi } from '@/apis/migration-api';
import TransferBusinessRegister from '@/components/business-bank-info/business-info/transfer-business-register.vue';
import SafeHtml from '@/components/common/safe-html.vue';
import { showAlert, showConfirm } from '@/composables/useDialog';
import { BUSINESS_TYPE, GAME_TYPE } from '@/constants/business-bank.const';
import { MODE } from '@/constants/common.const';
import { YEAR_FORMAT } from '@/constants/date-time.const';
import { COMMON_ERROR_MESSAGE_KEY, STATUS_CODE } from '@/constants/error.const';
import { MigrationTarget } from '@/constants/migration.const';
import { BRAND_PAGE_URL } from '@/constants/url.const';
import { Confirmation, Gender, Sex } from '@/enums/common.enum';
import { MIGRATION_STATUS } from '@/enums/migration.enum';
import { createBusinessInfoMapper } from '@/mappers/business.mapper';
import { useAppStore } from '@/stores/app.store';
// import { useBusinessBankStore } from '@/stores/business-bank.store';
import { useUserStore } from '@/stores/user.store';
import type { BusinessInfoModel } from '@/types/business-info/business-model.type';
import type { ErrorResponse } from '@/types/common/common.type';
import type {
  ApproveMigrationGroupResponse,
  ApproveMigrationRequest
} from '@/types/migration.type';
import { getConfigs, redirectTo } from '@/utils/common.util';
import { formatDateTime } from '@/utils/date.util';

definePageMeta({
  layout: 'empty',
  middleware: [
    'check-login-middleware',
    'check-member-register-middleware',
    'check-user-age-middleware',
    'check-agree-term-middleware'
    // 'check-business-infos-middleware'
  ]
});

const { locale, t } = useI18n();
const route = useRoute();
const groupId = route.query.group_id as string;
const mode = route.query.mode as string;

const appStore = useAppStore();

const { gdsInfo } = storeToRefs(appStore);

// const businessBankStore = useBusinessBankStore();
// const { businessInfos, businessStatus } = storeToRefs(businessBankStore);
const userStore = useUserStore();
const { userInfo } = storeToRefs(userStore);

const gender = computed(() => {
  // if (businessStatus.value !== '' || businessStatus.value === BUSINESS_BANK_STATUS.REQUEST) {
  //   return businessInfos.value?.individual?.gender || '';
  // }

  return userInfo.value?.sex === Sex.FEMALE ? Gender.FEMALE : Gender.MALE;
});

const inputBirth = computed(() => {
  // if (businessStatus.value !== '' || businessStatus.value === BUSINESS_BANK_STATUS.REQUEST) {
  //   return businessInfos.value?.individual?.inputBirth || '';
  // }

  try {
    return userInfo.value?.birthDt ? formatDateTime(userInfo.value.birthDt, YEAR_FORMAT) : '';
  } catch {
    return '';
  }
});

const migrationInfo = ref<ApproveMigrationGroupResponse>();

const isEdit = computed(() => mode === MODE.EDIT);
const isDisabled = computed(() => mode === MODE.VIEW);

const { handleSubmit } = useForm<BusinessInfoModel>({
  initialValues: {
    individual: {
      license: Confirmation.NO,
      nationality: gdsInfo.value?.nation,
      countryTaxEqualYn: true,
      taxCountry: gdsInfo.value?.nation,
      name: '',
      gender: gender.value,
      inputBirth: inputBirth.value ?? '',
      birth: '',
      email: '',
      mobileCd: gdsInfo.value?.nation,
      mobileNo: '',
      uploadResidentName: '',
      uploadResident: [],
      gameType: GAME_TYPE.DEVELOPER,
      subBizInfo: [],
      staffInfo: {
        name: '',
        mobileCd: gdsInfo.value?.nation,
        mobileNo: '',
        email: ''
      },
      settInfo: {
        name: '',
        mobileCd: gdsInfo.value?.nation,
        mobileNo: '',
        email: ''
      },
      businessInfo: {
        nation: gdsInfo.value?.nation,
        name: '',
        ceoName: '',
        mobileCd: '',
        mobileNo: '',
        zipCode: undefined,
        city: '', // TAIWAN: city - ward
        ward: '',
        district: '', // VN: district - province
        province: '',
        streetName: '',
        buildingName: '',
        cityName: '',
        cityGroup: '',
        address: '',
        detailAddress: '',
        koreaBusinessRegistrationNumber: undefined,
        koreaBusinessRegistrationContract: '',
        koreaBusinessRegistrationContractNo: [],
        gameDevelopPublishRegistrationNumber: '',
        gameDevelopPublishRegistrationContract: '',
        gameDevelopPublishRegistrationContractNo: [],
        telecommunicationRegistrationNumber: '',
        telecommunicationRegistrationContract: '',
        telecommunicationRegistrationContractNo: [],
        registrationNo: undefined,
        businessRegistrationContractNo: [],
        taxId: '',
        type: '',
        businessType: BUSINESS_TYPE.INDIVIDUAL_BUSINESS,
        subBizDescription: ''
      }
    },
    groupTransfer: {
      groupName: ''
    }
  },
  keepValuesOnUnmount: true
});

const goToCompletedPage = async () => {
  const { STUDIO_V2_URL } = getConfigs();
  const urlCompleted = `${STUDIO_V2_URL}/${locale.value}/group-transfer/completed?query=${groupId}`;
  await redirectTo(urlCompleted, { external: true });
};

const handleApproveGroupMigration = async (groupId: string, params: BusinessInfoModel) => {
  try {
    const result = await showConfirm({
      content: t('studio.transfer.mgmt_history.request_review_cf_popup_msg'),
      confirmLabel: t('studio.common.popup_case_cf_btn'),
      cancelLabel: t('studio.common.popup_case_cancel_btn'),
      cancelVariant: 'outline'
    });

    if (result) {
      const requestParams = createBusinessInfoMapper(params);

      const approveRequest: ApproveMigrationRequest = {
        target: MigrationTarget.GROUP,
        id: groupId,
        approveYn: Confirmation.YES,
        groupInfo: {
          groupName: params?.groupTransfer?.groupName || ''
        },
        company: {
          groupId,
          companyInfo: requestParams.companyInfo,
          contracts: requestParams.contracts,
          managers: requestParams.managers,
          requestMemberNo: migrationInfo.value?.requestMember?.memberNo
        }
      };
      const res = await approveMigrationGroupApi(approveRequest);
      if (res) {
        goToCompletedPage();
      }
    }
  } catch (err) {
    // const error = err as ErrorResponse;
    await showAlert({
      content: t(COMMON_ERROR_MESSAGE_KEY),
      confirmLabel: t('studio.common.popup_case_cf_btn')
    });
    // console.error('err :>> ', error);
  }
};

const onSubmit = handleSubmit(async (value: BusinessInfoModel) => {
  try {
    await handleApproveGroupMigration(migrationInfo?.value?.groupId as string, value);
  } catch (err) {}
});

const init = async () => {
  try {
    const response = await fetchDetailGroupMigrationApi(groupId);
    if (response) {
      // Check if the recipient account is correct?
      const targetMemberNo = response?.targetMember?.memberNo;
      const memberNoLogin = userInfo.value?.memberNo;
      if (targetMemberNo !== memberNoLogin) {
        const acceptConfirm = await showAlert({
          content: t('studio.page.index.transfer_member.invalid_member_email_contents'),
          confirmLabel: t('studio.common.popup_case_cf_btn')
        });
        if (acceptConfirm) {
          const currentUrl = route.fullPath;
          goToLoginPage(currentUrl);
        }
      } else {
        if (response.migrationStatus === MIGRATION_STATUS.STOVE_REQUEST) {
          goToCompletedPage();
        }
        migrationInfo.value = response;
      }
    }
  } catch (err) {
    const error = err as ErrorResponse;

    if (error?.statusCode === STATUS_CODE.MIGRATION_NOT_AVAILABLE) {
      const acceptConfirm = await showAlert({
        content: t('studio.page.index.transfer_member.invalid_link'),
        confirmLabel: t('studio.common.popup_case_cf_btn')
      });
      if (acceptConfirm) {
        return await redirectTo(BRAND_PAGE_URL);
      }
    } else {
      const errorMessages = {
        [STATUS_CODE.MIGRATION_INVALID]:
          'studio.components.manage.transfer-dialog.confirm_trans_migration_invalid',
        [STATUS_CODE.MIGRATION_KEY_EXPIRE]: 'studio.page.index.transfer_member.invalid_link',
        [STATUS_CODE.MIGRATION_EMAIL_INVALID]:
          'studio.page.index.transfer_member.invalid_member_email_contents',
        [STATUS_CODE.GROUP_MIGRATION_ALREADY_PROGRESS]: 'studio.error.alert.3257',
        [STATUS_CODE.PROJECT_MIGRATION_ALREADY_PROGRESS]: 'studio.error.alert.3258',
        [STATUS_CODE.GROUP_MIGRATION_REACH_LIMIT]:
          'studio.components.manage.transfer-dialog.exceed_info_text',
        [STATUS_CODE.PROJECT_MIGRATION_REACH_LIMIT]: 'studio.page.migration.exception.project_limit'
      };

      const messageKey = errorMessages[error?.statusCode] || COMMON_ERROR_MESSAGE_KEY;
      await showAlert({
        content: t(messageKey),
        confirmLabel: t('studio.common.popup_case_cf_btn')
      });
    }
  }
};

init();
</script>
