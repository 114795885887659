export enum MIGRATION_STATUS {
  MIG_REQUEST = 'MIG_REQUEST', // 이관 신청 중
  MIG_SUCCESS = 'MIG_SUCCESS', // 이관 완료
  MIG_CANCEL = 'MIG_CANCEL', // 이관 취소
  MIG_DENY = 'MIG_DENY', // 이관 거절
  STOVE_REQUEST = 'STOVE_REQUEST', // 검수 대기
  STOVE_PROGRESS = 'STOVE_PROGRESS', // 검수 중
  STOVE_DENY = 'STOVE_DENY', // 검수 반려
  STOVE_MIG_READY = 'STOVE_MIG_READY', // stove 검수 대기중
  STOVE_APPROVE = 'STOVE_APPROVE' // stove 이관 대기
}
