<template>
  <div class="flex gap-24 mt-40">
    <div class="flex-1">
      <st-box v-if="haveLicense === ''">
        <license-check :disabled="disabled" />
      </st-box>

      <group-transfer-license
        v-if="haveLicense === Confirmation.YES"
        :modeDisabled="disabled"
        :isEdit="isEdit"
      />

      <!-- don't have license -->
      <group-transfer-no-license v-else :modeDisabled="disabled" :isEdit="isEdit" />

      <div class="mt-40 flex justify-center gap-16">
        <s-button
          variant="primary"
          size="lg"
          class="!min-w-160"
          :disabled="haveLicense === ''"
          @click="onSubmit"
        >
          {{ $t('studio.transfer.mgmt_history.request_review_btn') }}
        </s-button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useHead } from '@unhead/vue';
import { useFieldValue } from 'vee-validate';

import GroupTransferLicense from '@/components/business-bank-info/business-info/group-transfer-license/index.vue';
import GroupTransferNoLicense from '@/components/business-bank-info/business-info/group-transfer-no-license/index.vue';
import LicenseCheck from '@/components/business-bank-info/business-info/license-check.vue';
import StBox from '@/components/common/st-box.vue';
import { KAKAO_POSTAL_CODE_API } from '@/constants/common.const';
import { Confirmation } from '@/enums/common.enum';

useHead({
  script: [{ src: KAKAO_POSTAL_CODE_API }]
});

defineProps<{
  disabled: boolean;
  isEdit: boolean;
}>();

const emits = defineEmits<{
  onSubmit: [v: void];
}>();

const onSubmit = () => {
  emits('onSubmit');
};

const haveLicense = useFieldValue<string>('individual.license');
</script>
