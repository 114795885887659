import { FormatResponse } from 'seed-core';

import { useRequest } from '@/composables/useRequest';
import { STUDIO_API_URL } from '@/constants/api.const';
import { ApiMethod } from '@/enums/api.enum';
import type {
  ApproveMigrationGroupResponse,
  ApproveMigrationRequest,
  ApproveMigrationResponse
} from '@/types/migration.type';
import { camelToSnake } from '@/utils/api.util';

const CONTEXT = 'v1';

// https://wiki.smilegate.net/pages/viewpage.action?pageId=459630560
export const fetchDetailGroupMigrationApi = async (
  groupId: string
): Promise<ApproveMigrationGroupResponse | undefined> => {
  const response = await useRequest<ApproveMigrationGroupResponse>(
    `${STUDIO_API_URL}/${CONTEXT}/migration/approval/group/${groupId}`
  );

  return response?.data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=456759127
export const approveMigrationGroupApi = async (
  params: ApproveMigrationRequest
): Promise<ApproveMigrationResponse | undefined> => {
  const response = await useRequest<ApproveMigrationResponse>(
    `${STUDIO_API_URL}/${CONTEXT}/migration/approve`,
    {
      method: ApiMethod.Put,
      data: camelToSnake(params),
      formatResponse: FormatResponse.Custom
    }
  );

  return response?.data;
};
